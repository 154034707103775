import React from "react"
import Coatings from "../components/lenses/coatings"
import LabLogos from "../components/lenses/labLogos"
import LensesTop from "../components/lenses/lensesTop"
import Progressives from "../components/lenses/progressives"
import ThinLenses from "../components/lenses/thinLenses"
import * as lensesStyles from "./lenses.module.scss"
import Seo from "../components/seo"

const Lenses = () => {
  return (
    <div className={lensesStyles.wrapper}>
      <Seo
        title="Lenses"
        description="
We offer a range of lenses from the world's most trusted surfacing labs. We assist you in determining exactly the right lenses for your vision and price point. We have in-house labs at both locations with ABO Certified opticians on staff to make sure your vision gets the care and accuracy it deserves."
      />
      <LensesTop />
      <ThinLenses />
      <Progressives />
      <Coatings />
      <LabLogos />
    </div>
  )
}

export default Lenses
