import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as lensesTopStyles from "./lensesTop.module.scss"

const LensesTop = () => {
  return (
    <div className={lensesTopStyles.wrapper}>
      <div className={lensesTopStyles.redLine}></div>
      <div>
        <div className={lensesTopStyles.draperyAndHeaderGrid}>
          <StaticImage
            className={lensesTopStyles.drapery}
            src="../../images/lenses/draperyLenses.png"
            placeholder="tracedSVG"
            alt="Illustration of hands presenting sunglass lenses under a green drapery. There is an eyeball in the top of the drapes."
          />
          <h1 className={lensesTopStyles.theLatest}>
            The Latest In Lens Technology
          </h1>
        </div>
        <div className={lensesTopStyles.draperyArticleWrapper}>
          <p className={lensesTopStyles.draperyParagraph}>
            We offer a range of lenses from the world's most trusted surfacing
            labs. We assist you in determining exactly the right lenses for your
            vision and price point. We have in-house labs at both locations with
            ABO Certified opticians on staff to make sure your vision gets the
            care and accuracy it deserves.
          </p>
        </div>
      </div>
    </div>
  )
}

export default LensesTop
