// extracted by mini-css-extract-plugin
export var antiGlareContentWrapper = "coatings-module--antiGlareContentWrapper--xfAL9";
export var antiGlareHeader = "coatings-module--antiGlareHeader--AROj6";
export var antiGlareHeaderWrapper = "coatings-module--antiGlareHeaderWrapper--Ui6UM";
export var antiGlareParagraph = "coatings-module--antiGlareParagraph--7DhoY";
export var antiGlarePhoto = "coatings-module--antiGlarePhoto--ckHHE";
export var antiGlareWrapper = "coatings-module--antiGlareWrapper--K-6UM";
export var blueLightContentWrapper = "coatings-module--blueLightContentWrapper--BSlHc";
export var blueLightHeader = "coatings-module--blueLightHeader--C7YUF";
export var blueLightHeaderWrapper = "coatings-module--blueLightHeaderWrapper--SGBNR";
export var blueLightParagraph = "coatings-module--blueLightParagraph--hFjt1";
export var blueLightWrapper = "coatings-module--blueLightWrapper--zti3U";
export var blueUVFlex = "coatings-module--blueUVFlex--NHbB3";
export var computerPhoto = "coatings-module--computerPhoto--XcuWj";
export var glareAndScratchFlex = "coatings-module--glareAndScratchFlex--FV1ON";
export var scratchContentWrapper = "coatings-module--scratchContentWrapper--pF0vp";
export var scratchHeader = "coatings-module--scratchHeader--llrbi";
export var scratchHeaderWrapper = "coatings-module--scratchHeaderWrapper--hdA0R";
export var scratchParagraph = "coatings-module--scratchParagraph--x2eiK";
export var scratchPhoto = "coatings-module--scratchPhoto--KrdCB";
export var scratchWrapper = "coatings-module--scratchWrapper--rqNrd";
export var uvContentWrapper = "coatings-module--uvContentWrapper--OY2jj";
export var uvHeader = "coatings-module--uvHeader--Nqpot";
export var uvHeaderWrapper = "coatings-module--uvHeaderWrapper--NroRJ";
export var uvParagraph = "coatings-module--uvParagraph--y8Tfr";
export var uvPhoto = "coatings-module--uvPhoto--a68A3";
export var uvWrapper = "coatings-module--uvWrapper--8wEep";