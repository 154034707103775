import React from "react"
import * as thinLensesStyles from "./thinLenses.module.scss"
import { StaticImage } from "gatsby-plugin-image"

const ThinLenses = () => {
  return (
    <div>
      <div className={thinLensesStyles.headerWrapper}>
        <h2 className={thinLensesStyles.header}>
          Thin & Impact Resistant Lenses
        </h2>
      </div>
      <div className={thinLensesStyles.contentWrapper}>
        <p className={thinLensesStyles.thinLensParagraph}>
          For higher prescriptions we carry state of the art hi-index lenses to
          keep your eyewear thin, light, comfortable, and attractive.
        </p>
        <StaticImage
          className={thinLensesStyles.lensThicknessPhoto}
          src="../../images/lenses/lensthickness.png"
          style={{ display: `block`, marginLeft: `auto`, marginRight: `auto` }}
          alt="A chart comparing lens thickness between various materials"
          placeholder="blurred"
        />
        <p className={thinLensesStyles.thinLensParagraph}>
          We also work with speciality materials such as Polycarbonate and
          Trivex for safety purposes.
        </p>
      </div>
    </div>
  )
}

export default ThinLenses
