import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as coatingsStyles from "./coatings.module.scss"

const Coatings = () => {
  return (
    <div>
      <div className={coatingsStyles.glareAndScratchFlex}>
        {/* anti-glare */}
        <div className={coatingsStyles.antiGlareWrapper}>
          <div className={coatingsStyles.antiGlareHeaderWrapper}>
            <h2 className={coatingsStyles.antiGlareHeader}>Anti-Glare</h2>
          </div>
          <div className={coatingsStyles.antiGlareContentWrapper}>
            <StaticImage
              className={coatingsStyles.antiGlarePhoto}
              src="../../images/lenses/anti-glare-pic.png"
              style={{
                display: `block`,
                marginLeft: `auto`,
                marginRight: `auto`,
              }}
              placeholder="blurred"
              alt="Illustration demonstrating lenses with and without anti-glare"
            />
            <p className={coatingsStyles.antiGlareParagraph}>
              Anti-glare coatings reduce annoying reflections on the surface of
              your lenses for the clearest, unobstructed vision possible.
            </p>
          </div>
        </div>
        {/* anti scratch */}
        <div className={coatingsStyles.scratchWrapper}>
          <div className={coatingsStyles.scratchHeaderWrapper}>
            <h2 className={coatingsStyles.scratchHeader}>Scratch-Resistance</h2>
          </div>
          <div className={coatingsStyles.scratchContentWrapper}>
            <p className={coatingsStyles.scratchParagraph}>
              We carry the very best in scratch resistant coatings. This clear
              coating applied to the lenses makes the surface much harder - up
              to 10 times harder than uncoated lenses.
            </p>
            <StaticImage
              src="../../images/lenses/scratch-pic.png"
              className={coatingsStyles.scratchPhoto}
              placeholder="blurred"
              style={{
                display: `block`,
                marginLeft: `auto`,
                marginRight: `auto`,
              }}
              alt="Illustration demonstrating an anti-scratch coating"
            />
          </div>
        </div>
      </div>
      <div className={coatingsStyles.blueUVFlex}>
        {/* blue light */}
        <div className={coatingsStyles.blueLightWrapper}>
          <div className={coatingsStyles.blueLightHeaderWrapper}>
            <h2 className={coatingsStyles.blueLightHeader}>
              Blue Light Filtering
            </h2>
          </div>
          <div className={coatingsStyles.blueLightContentWrapper}>
            <p className={coatingsStyles.blueLightParagraph}>
              We have specialized coatings which can help to filter out the
              harmful blue violet rays from computer screens and digital
              devices.
            </p>
            <StaticImage
              src="../../images/lenses/computer-pic.png"
              className={coatingsStyles.computerPhoto}
              style={{
                display: `block`,
                marginLeft: `auto`,
                marginRight: `auto`,
              }}
              alt="Vintage computer"
              placeholder="blurred"
            />
          </div>
        </div>
        {/* UV protection */}
        <div className={coatingsStyles.uvWrapper}>
          <div className={coatingsStyles.uvHeaderWrapper}>
            <h2 className={coatingsStyles.uvHeader}>UV Protection</h2>
          </div>
          <div className={coatingsStyles.uvContentWrapper}>
            <p className={coatingsStyles.uvParagraph}>
              All of our lenses come factory coated with UV A and B protection.
              We also offer tints for custom sunglasses.
            </p>
            <StaticImage
              src="../../images/lenses/uv-pic.png"
              className={coatingsStyles.uvPhoto}
              style={{
                display: `block`,
                marginLeft: `auto`,
                marginRight: `auto`,
              }}
              placeholder="blurred"
              alt="Woman relaxing on beach wearing sunglasses"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Coatings
