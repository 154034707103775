import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as labLogosStyles from "./labLogos.module.scss"

const LabLogos = () => {
  return (
    <div className={labLogosStyles.wrapper}>
      <div className={labLogosStyles.lensManufacturersHeaderWrapper}>
        <h2 className={labLogosStyles.lensManufacturersHeader}>
          Lens Manufacturers
        </h2>
      </div>
      <div className={labLogosStyles.contentWrapper}>
        <div className={labLogosStyles.logosWrapper}>
          <a
            target="__blank"
            href="https://www.essilor.com/"
            rel="noreferrer"
            className={labLogosStyles.links}
          >
            <StaticImage
              src="../../images/lenses/essilorLogo.png"
              alt="Essilor logo"
              style={{
                display: `block`,
                marginLeft: `auto`,
                marginRight: `auto`,
              }}
              placeholder="blurred"
            />
          </a>
          <a
            target="__blank"
            href="https://www.hoyavision.com/"
            rel="noreferrer"
            className={labLogosStyles.links}
          >
            <StaticImage
              src="../../images/lenses/hoya-logo.png"
              alt="Hoya logo"
              style={{
                display: `block`,
                marginLeft: `auto`,
                marginRight: `auto`,
              }}
              placeholder="blurred"
            />
          </a>
          <a
            target="__blank"
            href="https://www.shamir.com/"
            rel="noreferrer"
            className={labLogosStyles.links}
          >
            <StaticImage
              src="../../images/lenses/shamir-logo.png"
              alt="Shamir logo"
              style={{
                display: `block`,
                marginLeft: `auto`,
                marginRight: `auto`,
              }}
              placeholder="blurred"
            />
          </a>
          <a
            target="__blank"
            href="https://www.zeiss.com/vision-care/int/home.html"
            rel="noreferrer"
            className={labLogosStyles.links}
          >
            <StaticImage
              src="../../images/lenses/zeiss-logo.png"
              alt="Zeiss logo"
              style={{
                display: `block`,
                marginLeft: `auto`,
                marginRight: `auto`,
              }}
              placeholder="blurred"
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default LabLogos
