import React from "react"
import * as progressivesStyles from "./progressives.module.scss"
import { StaticImage } from "gatsby-plugin-image"

const Progressives = () => {
  return (
    <div>
      <div className={progressivesStyles.headerWrapper}>
        <h2 className={progressivesStyles.header}>
          Progressive & Multi-focal Lenses
        </h2>
      </div>
      {/* content */}
      <div className={progressivesStyles.contentWrapper}>
        <p className={progressivesStyles.progressivesParagraph}>
          For patients with Presbyopia, we work closely with you to determine
          precisely the multi-focal lens for your needs. We offer the most
          advanced in digitally surfaced progressive lens technology, in
          addition to more traditional line bifocals. and trifocals.
        </p>
        <StaticImage
          className={progressivesStyles.progressivesPhoto}
          src="../../images/lenses/multifocal-illustration.png"
          style={{ display: `block`, marginLeft: `auto`, marginRight: `auto` }}
          alt="An illustration showing the visual zones in a typical progressive lens"
          placeholder="blurred"
        />
      </div>
    </div>
  )
}

export default Progressives
